import ScratchStorage from "scratch-storage";

import defaultProject from "./default-project";

import api from "../lib/api";

/**
 * Wrapper for ScratchStorage which adds default web sources.
 * @todo make this more configurable
 */
class Storage extends ScratchStorage {
    constructor() {
        super();
        this.cacheDefaultProject();
    }

    /**
     * Store an asset by type & ID.
     * @param {AssetType} assetType - The type of asset to fetch. This also determines which asset store to use.
     * @param {?DataFormat} [dataFormat] - Optional: load this format instead of the AssetType's default.
     * @param {Buffer} data - Data to store for the asset
     * @param {?string} [assetId] - The ID of the asset to fetch: a project ID, MD5, etc.
     * @return {Promise.<object>} A promise for asset metadata
     */
    store(assetType, dataFormat, data, assetId) {
        const dataURItoFile = (data) => {
            return new File([data], `${assetId}.${dataFormat}}`, {
                type: dataFormat,
            });
        };
        dataFormat = dataFormat || assetType.runtimeFormat;
        const d = new FormData();
        d.append("file", dataURItoFile(data));
        return api
            .post(`${this.assetHost}/${assetId}/`, d)
            .then((response) => {
                this.builtinHelper._store(
                    assetType,
                    dataFormat,
                    data,
                    response.data.id
                );
                return response.data;
            })
            .catch((error) => {});
    }

    addOfficialScratchWebStores() {
        this.addWebStore(
            [this.AssetType.Project],
            this.getProjectGetConfig.bind(this),
            this.getProjectCreateConfig.bind(this),
            this.getProjectUpdateConfig.bind(this)
        );
        this.addWebStore(
            [
                this.AssetType.ImageVector,
                this.AssetType.ImageBitmap,
                this.AssetType.Sound,
            ],
            this.getAssetGetConfig.bind(this),
            // We set both the create and update configs to the same method because
            // storage assumes it should update if there is an assetId, but the
            // asset store uses the assetId as part of the create URI.
            this.getAssetCreateConfig.bind(this),
            this.getAssetCreateConfig.bind(this)
        );
        this.addWebStore(
            [this.AssetType.Sound],
            (asset) =>
                `static/extension-assets/scratch3_music/${asset.assetId}.${asset.dataFormat}`
        );
    }
    setProjectHost(projectHost) {
        this.projectHost = projectHost;
    }
    getProjectGetConfig(projectAsset) {
        let publicProjectHost = "https://api.zhixinfuture.com/api/projects";
        if (window.location.hostname === "localhost") {
            publicProjectHost = "http://localhost:8000/api/projects";
        }
        return `${publicProjectHost}/${projectAsset.assetId}/`;
    }
    getProjectCreateConfig() {
        return {
            url: `${this.projectHost}/`,
            withCredentials: true,
        };
    }
    getProjectUpdateConfig(projectAsset) {
        return {
            url: `${this.projectHost}/${projectAsset.assetId}/`,
            withCredentials: true,
        };
    }

    setAssetHost(assetHost) {
        this.assetHost = assetHost;
    }
    getAssetGetConfig(asset) {
        //return `${this.assetHost}/${asset.assetId}/get/`;
        return `${this.assetHost}/${asset.assetId}/${asset.dataFormat}/`;
    }
    getAssetCreateConfig(asset) {
        return {
            // There is no such thing as updating assets, but storage assumes it
            // should update if there is an assetId, and the asset store uses the
            // assetId as part of the create URI. So, force the method to POST.
            // Then when storage finds this config to use for the "update", still POSTs
            method: "post",
            url: `${this.assetHost}/${asset.assetId}/`,
            withCredentials: true,
        };
    }
    setTranslatorFunction(translator) {
        this.translator = translator;
        this.cacheDefaultProject();
    }
    cacheDefaultProject() {
        const defaultProjectAssets = defaultProject(this.translator);
        defaultProjectAssets.forEach((asset) =>
            this.builtinHelper._store(
                this.AssetType[asset.assetType],
                this.DataFormat[asset.dataFormat],
                asset.data,
                asset.id
            )
        );
    }
}

const storage = new Storage();

export default storage;
