import queryString from "query-string";
import api from "../lib/api";
import storage from "../lib/storage";

/**
 * Save a project JSON to the project server.
 * This should eventually live in scratch-www.
 * @param {number} projectId the ID of the project, null if a new project.
 * @param {object} vmState the JSON project representation.
 * @param {object} params the request params.
 * @property {?number} params.originalId the original project ID if a copy/remix.
 * @property {?boolean} params.isCopy a flag indicating if this save is creating a copy.
 * @property {?boolean} params.isRemix a flag indicating if this save is creating a remix.
 * @property {?string} params.title the title of the project.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
export default function (projectId, vmState, params) {
    const opts = {
        body: vmState,
        // If we set json:true then the body is double-stringified, so don't
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
    };
    console.log(projectId);
    const creatingProject =
        projectId === null ||
        projectId === "0" ||
        projectId === 0 ||
        typeof projectId === "undefined";
    const queryParams = {};
    if (params.hasOwnProperty("originalId"))
        queryParams.original_id = params.originalId;
    if (params.hasOwnProperty("isCopy")) queryParams.is_copy = params.isCopy;
    if (params.hasOwnProperty("isRemix")) queryParams.is_remix = params.isRemix;
    if (params.hasOwnProperty("title")) queryParams.title = params.title;
    let qs = queryString.stringify(queryParams);
    if (qs) qs = `?${qs}`;
    if (creatingProject) {
        Object.assign(opts, {
            method: "post",
            url: `${storage.projectHost}/${qs}`,
        });
    } else {
        Object.assign(opts, {
            method: "put",
            url: `${storage.projectHost}/${projectId}${qs}`,
        });
    }

    if (opts.method === "post") {
        const data = new FormData();
        data.append("name", params.title);
        data.append("code", opts.body);
        data.append("type", "Scratch");
        data.append("is_public", true);
        return api
            .post("myprojects/", data)
            .then((response) => {
                let body = JSON.parse(response.data.code);
                body.id = projectId;
                if (creatingProject) {
                    body.id = response.data.id;
                }
                return body;
            })
            .catch((error) => {});
    } else if (opts.method === "put") {
        const data = new FormData();
        data.append("name", params.title);
        data.append("code", opts.body);
        return api
            .patch(`myprojects/${projectId}/`, data)
            .then((response) => {
                let body = JSON.parse(response.data.code);
                body.id = projectId;
                if (creatingProject) {
                    body.id = response.data.id;
                }
                return body;
            })
            .catch((error) => {});
    }

    /*return new Promise((resolve, reject) => {
        xhr(opts, (err, response) => {
            if (err) return reject(err);
            if (response.statusCode !== 200) return reject(response.statusCode);
            let body;
            try {
                // Since we didn't set json: true, we have to parse manually
                body = JSON.parse(response.body);
            } catch (e) {
                return reject(e);
            }
            body.id = projectId;
            if (creatingProject) {
                body.id = body["content-name"];
            }
            resolve(body);
        });
    });*/
}
