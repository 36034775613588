const UPDATE_SESSION = "scratch-gui/session/UPDATE_SESSION";

const initialState =
    { session: JSON.parse(localStorage.getItem("user")) } || {};

const reducer = function (state, action) {
    if (typeof state === "undefined") state = initialState;
    switch (action.type) {
        case UPDATE_SESSION:
            return Object.assign({}, state, {
                session: action.session,
            });
        default:
            return state;
    }
};

const setSession = function (session) {
    return {
        type: UPDATE_SESSION,
        session: session,
    };
};

const initSession = function (currentState, session) {
    return Object.assign({}, currentState, {
        session: session,
    });
};

export {
    reducer as default,
    initialState as sessionInitialState,
    initSession,
    setSession,
};
