import axios from "axios";
let url = "https://api.zhixinfuture.com/api";
if (window.location.hostname === "localhost") {
    url = "http://localhost:8000/api";
}
const instance = axios.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
